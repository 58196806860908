import { useMemo, useRef, useState } from "react";
import { useNotify } from "../../../components/Notify";
import { editSplit } from "../../../../components/WeGantt";
import { FormifyInput } from "../../../../components/WePack";
import req from "../../../../modules/Request";
import Moment from "../../../../components/Moment";
import { clsx } from "../../../../modules/Utils";


const SplitEditForm = ({ task, split, setTasks, onClose, holidays, workingHours }) => {
  const { notify } = useNotify();
  
  const [ start, setStart ] = useState(split.start);
  const [ end, setEnd ] = useState(split.end);
  const [ duration, setDuration ] = useState(split.duration);

  const calculateTimeout = useRef(undefined);

  const [ min, max ] = useMemo(() => {
    const index = task.dates.findIndex(d => d.id === split.id);

    const min = task.dates[ index - 1 ]?.end ?? undefined;
    const max = task.dates[ index + 1 ]?.start ?? undefined;

    return [ min, max ];
  }, [ task, split ]);

  const reqData = useMemo(() => {
    return editSplit(
      workingHours,
      holidays,
      task,
      split,
      start,
      end,
      duration,
      duration == split.duration ? (
        "move"
      ) : (
        "resize"
      )
    );
  }, [ start, end, duration ]);



  const handleSubmit = (e) => {
    e.preventDefault();

    req("sync-task", { task: reqData }).then((res) => {
      if (res.error) {
        notify(res.error, 'Errore durante la modifica del task.', 'error');
      }
    });
    setTasks(tasks => tasks.map(t => t.id === reqData.id ? { ...reqData, moved: 1 } : t));
    onClose();
  };

  return (
    <form className="min-w-[220px] flex flex-col items-center text-center" onSubmit={ handleSubmit }>
      <div className="w-full font-semibold border-b border-gray-400">Modifica Split</div>
      
      <div className="flex flex-col justify-center items-center space-y-1 pb-2">
        <div className="flex flex-col w-full">
          <label>Data Inizio</label>

          <FormifyInput required
            type="date"
            value={ start }
            min={ min }
            onInvalid={ (e) => {
              e.preventDefault();
              notify('Data Inizio', e.target.validationMessage, 'error');
            } }
            onInput={ (val) => {
              if (!holidays.includes(val)) {
                setStart(val);

                calculateTimeout.current && clearTimeout(calculateTimeout.current);

                if (new Date(val) != 'Invalid Date' && new Date(val) > new Date('2000-01-01')) {
                  setEnd(Moment(val).end(duration / workingHours, holidays));
                }
              } else {
                notify('Data Inizio', "La data non può essere un giorno festivo.", 'error');
              }
            } }
          />
        </div>

        <div className="flex flex-col w-full">
          <label>Data Fine</label>

          <FormifyInput required
            type="date"
            min={ start }
            max={ max }
            value={ end }
            onInvalid={ (e) => {
              e.preventDefault();
              notify('Data Fine', e.target.validationMessage, 'error');
            } }
            onInput={ (val) => {
              if (!holidays.includes(val)) {
                setEnd(val);
                setDuration(Moment(start).duration(val, holidays) * workingHours);
              } else {
                notify('Data Fine', "La data non può essere un giorno festivo.", 'error');
              }
            } }
          />
        </div>

        <div className="flex flex-col w-full">
          <label>Durata (Ore)</label>

          <FormifyInput required
            type="number"
            min={ 0 }
            value={ duration }
            onInput={ (val) => {
              setDuration(+val);
              setEnd(Moment(start).end(Math.ceil(+val / workingHours), holidays));
            } }
          />
        </div>

        <button
          className={ clsx(
            "border disabled:bg-green-300 disabled:border-green-200 rounded-sm",
            "px-4 py-0.5 duration-75 bg-green-500 hover:bg-green-400 border-green-400",
            "hover:border-green-300 text-white"
          ) }
        >
          Applica
        </button>
      </div>
    </form>
  );
};

export default SplitEditForm;