
import { Button, Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Dialog, DialogPanel, DialogTitle, Input, Listbox, ListboxButton, ListboxOption, ListboxOptions, Radio, RadioGroup, Textarea, Transition, TransitionChild } from "@headlessui/react";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { clsx, switcher } from "../modules/Utils";
import LoadingAnimation from "../assets/images/loading.svg";

const Flaticon = ({ name, type, size, className, style }) => {
  return (
    <i
      style={ { ...style, fontSize: size } }
      className={ clsx(className, "flex justify-center items-center fi", "fi" + (type ? "-" + type : "") + "-" + (name ?? 'question')) }
    />
  );
};

const FormifyInput = ({
  refe,
  onInput,
  onCheck,
  onChange,
  onBlur,
  onFocus,
  onInvalid,
  className,
  addClasses,
  disabled,
  required,
  type,
  step,
  defaultValue,
  min,
  max,
  maxLength,
  value,
  placeholder,
  advanced,
  timeout
}) => {
  const advTimeout = useRef(undefined);
  const [ advValue, setAdvValue ] = useState(defaultValue);

  if (advanced) {
    return (
      <input
        ref={ refe }
        required={ required }
        disabled={ disabled }
        type={ type }
        step={ step }
        defaultValue={ defaultValue }
        value={ advValue }
        min={ min }
        max={ max }
        maxLength={ maxLength }
        placeholder={ placeholder }
        className={ clsx(
          className ?? "px-2 py-0 border-0 border-b border-gray-300 bg-gray-100",
          className ?? "focus:ring-0 focus:ring-b disabled:text-gray-500 disabled:bg-gray-200",
          addClasses
        ) }
        onInvalid={ onInvalid }
        onInput={ (e) => {
          e.preventDefault();
          setAdvValue(e.target.value, e);
        } }
        onBlur={ (e) => {
          e.preventDefault();
          onBlur && onBlur(e.target.value, e);
        } }
        onFocus={ (e) => {
          e.preventDefault();
          onFocus && onFocus(e.target.value, e);
        } }
        onChange={ (e) => {
          e.preventDefault();

          if (advTimeout.current) {
            clearTimeout(advTimeout.current);
          }

          advTimeout.current = setTimeout(() => {
            onChange && onChange(e.target.value);
            onCheck && onCheck(e.target.checked);
          }, timeout ?? 500);
        } }
      />
    );
  } else {
    return (
      <input
        ref={ refe }
        required={ required }
        disabled={ disabled }
        type={ type }
        step={ step }
        defaultValue={ defaultValue }
        value={ value }
        min={ min }
        max={ max }
        maxLength={ maxLength }
        placeholder={ placeholder }
        className={ clsx(
          className ?? "px-2 py-0 border-0 border-b border-gray-300 bg-gray-100",
          className ?? "focus:ring-0 focus:ring-b disabled:text-gray-500 disabled:bg-gray-200",
          addClasses
        ) }
        onInvalid={ onInvalid }
        onInput={ (e) => {
          e.preventDefault();
          onInput && onInput(e.target.value, e);
        } }
        onBlur={ (e) => {
          e.preventDefault();
          onBlur && onBlur(e.target.value, e);
        } }
        onFocus={ (e) => {
          e.preventDefault();
          onFocus && onFocus(e.target.value, e);
        } }
        onChange={ (e) => {
          e.preventDefault();
          onChange && onChange(e.target.value, e);
          onCheck && onCheck(e.target.checked, e);
        } }
      />
    );
  }

};

const FormifySelect = ({ onChange, className, addClasses, options, value }) => {
  return <Listbox value={ value } onChange={ (val) => onChange(val) } >
    <ListboxButton
      className={ clsx(
        className ?? "border flex border-gray-300 rounded-md px-1.5 py-0.5 focus:ring-0  focus:border-blue-600 w-full "
        + "invalid:border-red-600 disabled:text-gray-500 disabled:bg-gray-200 font-medium",
        addClasses
      ) }
    >
      { options.find(option => option.value === value)?.label }
    </ListboxButton>
    <ListboxOptions
      anchor="bottom"
      transition
      className="z-50 border w-[var(--button-width)] bg-white rounded-md divide-y empty:invisible shadow-[0_0_4px_0_#00000020]"
    >
      { options.length > 0 && options?.map((option, index) => (
        <ListboxOption
          key={ option.value }
          value={ option.value }
          className="data-[focus]:bg-gray-200 cursor-pointer flex"
        >
          <div className="w-full h-full flex mx-2 py-1 space-x-2 hover:space-x-0 overflow-hidden items-center hover:items-start hover:flex-col duration-75">
            <div className="flex space-x-2 duration-75 text-nowrap">
              <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-600", value === option.value ? "visible" : "invisible") } />
              <span className="flex-1">{ option.label }</span>
            </div>
          </div>
        </ListboxOption>
      )) }
    </ListboxOptions>
  </Listbox>;
};

const FormifyPalette = ({ onChange, value, className, addClasses, disabled, required, ...props }) => {
  return (
    <div className="flex justify-between">
      <div className="font-medium">{ value }</div>
      <Input
        { ...props }
        disabled={ disabled }
        required={ required }
        type="color"
        value={ value }
        className={ clsx(
          className ?? "border-0 bg-transparent w-12 h-8 appearance-none cursor-pointer",
          addClasses
        ) }
        onChange={ (e) => onChange(e.target.value) }
      />
    </div>
  );
};

const FormifyTextarea = ({ onInput, value, className, addClasses, disabled, maxLength, required, rows, ...props }) => {
  return (
    <Textarea
      { ...props }
      required={ required }
      disabled={ disabled }
      maxLength={ maxLength }
      value={ value }
      rows={ rows }
      className={ clsx(
        className ?? "border border-gray-300 rounded-md px-1.5 py-0.5 focus:ring-0  focus:border-blue-600 resize-none "
        + "invalid:border-red-600 disabled:text-gray-500 disabled:bg-gray-200 font-medium",
        addClasses
      ) }
      onInput={ (e) => onInput(e.target.value) }
    />
  );
};

const FormifyCombobox = ({ onChange, className, addClasses, options, width, disabled, value, autoComplete, required, CustomOption, ...props }) => {
  const [ query, setQuery ] = useState('');

  const filteredOptions = useMemo(() => {
    return query !== '' ? (
      options.filter(option => {
        const splitQ = query.toLowerCase().split(' ');

        const validated = splitQ.every(q => {
          const label = option.label.toLowerCase();
          const hashtags = option.hashTags?.map(tag => tag.toLowerCase()) ?? [];

          if (q.startsWith('#') && hashtags?.length > 0) {
            const hashtagsValidated = hashtags?.some(tag => tag.includes(q.slice(1)));
            const labelValidated = label.includes(q);

            return hashtagsValidated || labelValidated;
          } else {

            return label.includes(q);
          }
        });

        return validated;
      })
    ) : (
      options
    );
  }, [ query, options ]);


  return (
    <div className="relative w-full">
      <Combobox immediate value={ value } onChange={ onChange } onClose={ () => setQuery('') }>
        <div className="relative w-full">
          <ComboboxInput
            required={ required }
            disabled={ disabled }
            autoComplete={ autoComplete }
            style={ { width } }
            className={ clsx(
              className ?? "px-2 py-0 border-0 border-b border-gray-300 bg-gray-100",
              className ?? "focus:ring-0 focus:ring-b disabled:text-gray-500 disabled:bg-gray-200",
              addClasses
            ) }
            displayValue={ (value) => options.find(option => option.value === value)?.label }
            onChange={ (event) => setQuery(event.target.value) }
          />

          <ComboboxButton className="group absolute inset-y-0 right-0 px-3.5">
            <Flaticon
              name="angle-down"
              type="bs"
              size={ 12 }
              className="text-gray-500 group-data-[hover]:fill-white"
            />
          </ComboboxButton>
        </div>
        <ComboboxOptions
          anchor="bottom start"
          style={ { width } }
          className={ clsx(
            "z-50 border bg-white empty:invisible shadow-[0_0_4px_0_#00000020]",
            props.contexture && 'contexture'
          ) }
        >
          { filteredOptions.map((option, i) => (
            <ComboboxOption
              key={ i }
              value={ option.value }
              className="data-[focus]:bg-gray-200 cursor-pointer flex m-1 rounded-sm"
            >
              { ({ focus, selected }) => <>
                { CustomOption ? (
                  <CustomOption
                    focus={ focus }
                    selected={ selected }
                    label={ option.label }
                    value={ option.value }
                  />
                ) : (
                  <div
                    className={ clsx(
                      "w-full h-full flex mx-2 space-x-2 hover:space-x-0 overflow-hidden items-center ",
                      "hover:items-start hover:flex-col duration-75 [&:hover>.label]:text-wrap [&:hover>.hashtags]:flex-wrap"
                    ) }
                  >
                    <div className="flex space-x-2 label  duration-75 text-nowrap">
                      <Flaticon name="check" type="rr" size={ 14 } className={ clsx("text-green-600", value === option.value ? "visible" : "invisible") } />
                      <span className="flex-1">{ option.label }</span>
                    </div>
                    <span className="hashtags flex overflow-hidden space-x-1 data-[selected]:font-bold duration-75 ">
                      { option.hashTags?.map((tag, i) => (<>
                        <span key={ i } className="text-xs text-gray-800 rounded-md text-ellipsis">#<span className="text-gray-500">{ tag }</span></span>
                      </>)) }
                    </span>
                  </div>
                ) }
              </> }
            </ComboboxOption>
          )) }
        </ComboboxOptions>
      </Combobox>
    </div>
  );
};

const FormifyLevel = ({ onChange, from, to, disabled, value }) => {
  return (
    <RadioGroup
      disabled={ disabled }
      value={ value }
      onChange={ onChange }
    >
      <div className="flex justify-between space-x-1">
        { Array.from({ length: to - from + 1 }, (_, i) => i + from).map((level, i) => (
          <Radio
            key={ i }
            value={ level }
            className={ clsx(
              "flex flex-1 cursor-pointer justify-center items-center w-6 h-6 rounded-md",
              "font-semibold bg-white text-gray-800 shadow-[0_0_2px_0_#00000060]",
              "data-[checked]:bg-blue-600 data-[checked]:text-white",
              "data-[disabled]:bg-gray-200 data-[disabled]:text-gray-800"
            ) }
          >
            { level }
          </Radio>
        )) }
      </div>
    </RadioGroup>
  );
};

const FormifyMultiSelect = ({ children, values, onChange, noEmpty }) => {
  const selectedAll = values.length === React.Children.count(children.filter(child => child.type === FormifyMultiOption));
  const options = React.Children.map(children, child => child.props.value);

  const handleSelect = (value) => {
    if (values.includes(value)) {
      const newValues = values.filter(val => val !== value);
      if (noEmpty && newValues.length === 0) {
        return;
      } else {
        onChange(newValues);
      }
    } else {
      onChange([ ...values, value ]);
    }
  };

  const handleSelectAll = () => {
    if (!noEmpty && selectedAll) {
      onChange([]);
    } else {
      onChange(options);
    }
  };

  useEffect(() => {
    if (noEmpty && values.length === 0) {
      onChange(options);
    }
  }, []);

  return React.Children.map(children, (child, i) => {
    if (child.type === FormifyMultiOption) {
      return (
        <FormifyMultiOption
          key={ i }
          className={ child.props.className }
          onClick={ () => handleSelect(child.props.value) }
          isSelected={ values.includes(child.props.value) }
        >
          { child.props.children }
        </FormifyMultiOption>
      );
    } else if (child.type === FormifyMultiAll) {
      return (
        <FormifyMultiAll
          key={ i }
          className={ child.props.className }
          onClick={ handleSelectAll }
          isSelectedAll={ selectedAll }
        >
          { child.props.children }
        </FormifyMultiAll>
      );
    } else {
      return null;
    }
  });
};

const FormifyMultiOption = ({ children, className, ...props }) => {
  return (
    <button onClick={ props.onClick }>
      { children instanceof Function ? children({ isSelected: props.isSelected }) : children }
    </button>
  );
};

const FormifyMultiAll = ({ children, className, ...props }) => {
  return (
    <button onClick={ props.onClick }>
      { children instanceof Function ? children({ isSelectedAll: props.isSelectedAll }) : children }
    </button>
  );
};

const Contexture = ({ children }) => {
  const [ show, setShow ] = useState(false);


  return (
    <div className="relative">
      { React.Children.map(children, (child, index) => {
        if (child.type === ContextureSwitch) {
          return (
            <ContextureSwitch
              key={ index }
              { ...child.props }
              onClick={ () => setShow(!show) }
            >
              { child.props.children }
            </ContextureSwitch>
          );
        } else if (child.type === ContexturePanel) {
          return (
            <ContexturePanel
              key={ index }
              { ...child.props }
              show={ show }
              onClose={ () => setShow(false) }
            >
              { child.props.children }
            </ContexturePanel>
          );
        } else {
          return null;
        }
      }) }
    </div>
  );
};

const ContextureSwitch = ({ onClick, className, children }) => {
  return (
    <button
      onClick={ onClick }
      className={ className }
    >
      { children }
    </button>
  );
};

const ContexturePanel = ({ show, onClose, position, children, addClasses, className, simple, enter, enterFrom, enterTo, leave, leaveFrom, leaveTo, outClose }) => {
  const DefaultClassName = clsx(
    "w-full border focus:outline-none border-gray-300 bg-white rounded-sm",
    "shadow-lg flex flex-col items-stretch justify-center p-1 min-w-36 z-50",
    addClasses
  );

  const firstEnter = useRef(false);

  const isFirstOpen = useRef(true);

  const handleMouseEnter = () => {
    if (!firstEnter.current) {
      firstEnter.current = true;
    }
  };

  useEffect(() => {
    if (!show) {
      isFirstOpen.current = true;
      return;
    }

    const handleClickOutside = (e) => {
      console.log(e.target)

      if (e.target.closest('.contexture') || e.target.classList.contains('contexture')) return;
      if (isFirstOpen.current) return;

      isFirstOpen.current = true;
      firstEnter.current = false;

      onClose();
    };

    window.addEventListener('mousedown', handleClickOutside);

    setTimeout(() => isFirstOpen.current = false, 50);
    return () => window.removeEventListener('mousedown', handleClickOutside);
  }, [ show ]);

  const handleMouseLeave = () => {
    if (firstEnter.current && outClose) {
      firstEnter.current = false;
      isFirstOpen.current = true;
      onClose();
    }
  };

  if (children instanceof Function) {
    children = children({ onClose });
  }

  if (simple) {
    if (position && position?.x && position?.y) {
      return (
        <Transition
          as="div"
          show={ show }
          onMouseEnter={ handleMouseEnter }
          onMouseLeave={ handleMouseLeave }
          className={ clsx(
            "fixed z-50 text-nowrap outline-none contexture",
            (position?.y > window.innerHeight * 0.75) && "-translate-y-full",
            (position?.x > (window.innerWidth * 0.75)) && "-translate-x-full",
          ) }
          style={ { left: position?.x, top: position?.y } }
        >
          { position?.y > window.innerHeight * 0.75 && position?.x > window.innerWidth * 0.75 ? (
            <TransitionChild
              as="div"
              className={ DefaultClassName }
              enter="transition transform duration-100 ease-out"
              enterFrom="opacity-0 scale-0 translate-x-1/2 translate-y-1/2"
              enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
              leave="transition transform duration-100 ease-in"
              leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
              leaveTo="opacity-0 scale-0 translate-x-1/2 translate-y-1/2"
            >
              { children }
            </TransitionChild>
          ) : position?.y > window.innerHeight * 0.75 ? (
            <TransitionChild
              as="div"
              className={ DefaultClassName }
              enter="transition transform duration-100 ease-out"
              enterFrom="opacity-0 scale-0 -translate-x-1/2 translate-y-1/2"
              enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
              leave="transition transform duration-100 ease-in"
              leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
              leaveTo="opacity-0 scale-0 -translate-x-1/2 translate-y-1/2"
            >
              { children }
            </TransitionChild>
          ) : position?.x > window.innerWidth * 0.75 ? (
            <TransitionChild
              as="div"
              className={ DefaultClassName }
              enter="transition transform duration-100 ease-out"
              enterFrom="opacity-0 scale-0 translate-x-1/2 -translate-y-1/2"
              enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
              leave="transition transform duration-100 ease-in"
              leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
              leaveTo="opacity-0 scale-0 translate-x-1/2 -translate-y-1/2"
            >
              { children }
            </TransitionChild>
          ) : (
            <TransitionChild
              as="div"
              className={ DefaultClassName }
              enter="transition transform duration-100 ease-out"
              enterFrom="opacity-0 scale-0 -translate-x-1/2 -translate-y-1/2"
              enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
              leave="transition transform duration-100 ease-in"
              leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
              leaveTo="opacity-0 scale-0 -translate-x-1/2 -translate-y-1/2"
            >
              { children }
            </TransitionChild>
          ) }
        </Transition>
      );
    } else {
      return (
        <Transition
          as="div"
          show={ show }
          className={ clsx(className ?? DefaultClassName, "fixed z-50 outline-none contexture") }
          enter={ enter ?? "transition transform duration-100 ease-out" }
          enterFrom={ enterFrom ?? "opacity-0 scale-0" }
          enterTo={ enterTo ?? "opacity-100 scale-100" }
          leave={ leave ?? "transition transform duration-100 ease-in" }
          leaveFrom={ leaveFrom ?? "opacity-100 scale-100" }
          leaveTo={ leaveTo ?? "opacity-0 scale-0" }
          onMouseEnter={ handleMouseEnter }
          onMouseLeave={ handleMouseLeave }
        >
          { children }
        </Transition>
      );
    }
  } else {
    return (
      <Transition
        as={ Dialog }
        onClose={ onClose }
        show={ show }
        className={ clsx(
          "fixed z-50 text-nowrap outline-none",
          (position?.y > window.innerHeight * 0.75) && "-translate-y-full",
          (position?.x > (window.innerWidth * 0.75)) && "-translate-x-full",
        ) }
        style={ { left: position?.x, top: position?.y } }
      >
        { (position?.y > window.innerHeight * 0.75 && position?.x > window.innerWidth * 0.75) ? (
          <TransitionChild
            as="div"
            className={ clsx(DefaultClassName, addClasses) }
            enter="transition transform duration-100 ease-out"
            enterFrom="opacity-0 scale-0 translate-x-1/2 translate-y-1/2"
            enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
            leave="transition transform duration-100 ease-in"
            leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
            leaveTo="opacity-0 scale-0 translate-x-1/2 translate-y-1/2"
          >
            { children }
          </TransitionChild>
        ) : position?.y > window.innerHeight * 0.75 ? (
          <TransitionChild
            as="div"
            className={ clsx(DefaultClassName, addClasses) }
            enter="transition transform duration-100 ease-out"
            enterFrom="opacity-0 scale-0 -translate-x-1/2 translate-y-1/2"
            enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
            leave="transition transform duration-100 ease-in"
            leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
            leaveTo="opacity-0 scale-0 -translate-x-1/2 translate-y-1/2"
          >
            { children }
          </TransitionChild>
        ) : position?.x > window.innerWidth * 0.75 ? (
          <TransitionChild
            as="div"
            className={ clsx(DefaultClassName, addClasses) }
            enter="transition transform duration-100 ease-out"
            enterFrom="opacity-0 scale-0 translate-x-1/2 -translate-y-1/2"
            enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
            leave="transition transform duration-100 ease-in"
            leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
            leaveTo="opacity-0 scale-0 translate-x-1/2 -translate-y-1/2"
          >
            { children }
          </TransitionChild>
        ) : (
          <TransitionChild
            as="div"
            className={ clsx(DefaultClassName, addClasses) }
            enter="transition transform duration-100 ease-out"
            enterFrom="opacity-0 scale-0 -translate-x-1/2 -translate-y-1/2"
            enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
            leave="transition transform duration-100 ease-in"
            leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
            leaveTo="opacity-0 scale-0 -translate-x-1/2 -translate-y-1/2"
          >
            { children }
          </TransitionChild>
        ) }
      </Transition>
    );
  }
};

const ContextureCategory = ({ children, addClasses, label, ...props }) => {
  return (
    <div
      { ...props }
      className={ clsx(
        "border-t mt-2 pt-2.5 w-full flex flex-col items-stretch justify-center relative",
        addClasses
      ) }
    >
      { label && (
        <div className="text-sm max-w-full absolute bg-white top-0 left-[1px] px-2 -translate-y-1/2 text-gray-600 overflow-x-hidden overflow-ellipsis">
          { label }
        </div>
      ) }
      { children }
    </div>
  );
};

const ContextureButton = ({ children, addClasses, className, onClick, onMouseEnter, onMouseLeave }) => {
  return (
    <button
      type="button"
      className={ className ?? clsx(
        "flex justify-start items-center px-3 py-0.5 w-full rounded-sm",
        "space-x-2 text-nowrap hover:bg-gray-200",
        addClasses
      ) }
      onClick={ onClick }
      onMouseEnter={ onMouseEnter }
      onMouseLeave={ onMouseLeave }
    >
      { children }
    </button>
  );
};

const ContextureRow = ({ className, addClasses, children }) => {
  return (
    <div
      className={ className ?? clsx(
        "flex justify-start items-center px-1",
        "text-nowrap rounded-sm py-0.5 w-full",
        addClasses
      ) }
    >
      { children }
    </div>
  );
};

const ContextureCombobox = ({ children, addClasses, className, width, onSubmit, onChange, value, defaultValue, options }) => {
  const [ advValue, setAdvValue ] = useState(defaultValue);

  useEffect(() => {
    setAdvValue(defaultValue);
  }, [ defaultValue ]);

  if (onSubmit) {
    return (
      <form
        style={ { width } }
        className={ clsx(
          className ?? "flex justify-start items-center px-1 relative",
          className ?? "text-nowrap rounded-sm py-0.5",
          addClasses
        ) }
        onSubmit={ (e) => {
          e.preventDefault();
          onSubmit(advValue);
        } }
      >
        <FormifyCombobox
          onChange={ setAdvValue }
          value={ advValue }
          options={ options }
          className={ className }
          addClasses={ addClasses }
          width={ "calc(" + width + "px - 48px)" }
          contexture={ true }
        />

        <button
          className={ clsx(
            "flex justify-center items-center px-3 py-1 w-12 rounded-r-sm",
            "space-x-2 text-nowrap bg-green-500 hover:bg-green-400"
          ) }
        >
          { children }
        </button>
      </form>
    );
  } else if (onChange && value) {
    return (
      <ContextureRow>
        <FormifyCombobox
          onChange={ onChange }
          value={ value }
          options={ options }
          className={ true }
        />
      </ContextureRow>
    );
  }
};

const ContextureChild = ({ children, addClasses, className }) => {
  const timeout = useRef(null);
  const [ show, setShow ] = useState(false);

  return (
    <>
      { children.map((child, index) => {
        if (child.type === ContextureButton) {
          return (
            <ContextureButton
              key={ index }
              addClasses={ addClasses }
              className={ className }
              onMouseEnter={ () => {
                if (timeout.current) {
                  clearTimeout(timeout.current);
                };

                timeout.current = setTimeout(() => {
                  setShow(true);
                }, 500);
              } }
              onClick={ () => {
                if (timeout.current) {
                  clearTimeout(timeout.current);
                };

                setShow(true);
              } }
              onMouseLeave={ () => {
                if (timeout.current) {
                  clearTimeout(timeout.current);
                }
              } }
            >
              { child.props.children }
            </ContextureButton>
          );
        } else if (child.type === ContexturePanel) {
          return (
            <ContexturePanel simple outClose
              key={ index }
              show={ show }
              onClose={ () => setShow(false) }
              position={ child.props.position }
            >
              { child.props.children }
            </ContexturePanel>
          );
        } else {
          return null;
        }
      }) }
    </>
  );
};

const Modality = ({ show, label, children, buttons, onClose, className, image, icon, width, onSubmit, preventClose }) => {
  return (
    <Transition show={ show }>
      <Dialog as="div" className="z-50 focus:outline-none" onClose={ () => !preventClose && onClose() }>
        <TransitionChild
          as={ Fragment }
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed z-50 top-0 left-0 w-full h-full bg-black/50 backdrop-blur-sm" />
        </TransitionChild>

        <div className="fixed z-50 inset-0 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 scale">
            <TransitionChild
              as={ Fragment }
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-110"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-100"
            >
              <DialogPanel style={ { width: width ?? "448px", maxWidth: width ?? "448px" } } className="rounded-lg max-h-[90%] overflow-y-auto shadow-lg bg-white px-3 py-2 border border-gray-300">

                <form onSubmit={ onSubmit ?? ((e) => e.preventDefault()) }>
                  <div className="flex justify-center items-center space-x-4">
                    { icon ? (
                      <Flaticon name={ icon.name } type={ icon.type } className={ icon.className } size={ 42 } />
                    ) : image && (
                      <img src={ image } className="w-[48px]" />
                    ) }
                    <div className="flex flex-col flex-1">
                      <DialogTitle as="div" className="font-medium">
                        { label }
                      </DialogTitle>
                      <div className={ className }>
                        { children }
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex justify-end space-x-2">
                    { buttons.map(({ name, onClick, type = "button", styleSet = "default", className, disabled = false }, i) => {
                      return (
                        <Button
                          key={ i }
                          type={ type }
                          disabled={ disabled }
                          onClick={ () => onClick && onClick({ onClose }) }
                          className={ clsx(
                            className ?? "inline-flex items-center gap-2 rounded-md px-4 text-sm/6 font-semibold focus:outline-none",
                            className ?? switcher(styleSet, {
                              "error": "border border-red-400 bg-red-600 hover:bg-red-500 text-white disabled:bg-red-300 disabled:border-red-300 disabled:text-red-100 disabled:cursor-not-allowed",
                              "info": "border border-gray-300 bg-blue-600 hover:bg-blue-500 text-white disabled:bg-blue-300 disabled:border-blue-300 disabled:text-blue-100 disabled:cursor-not-allowed",
                              "success": "border border-gray-300 bg-green-600 hover:bg-green-500 text-white disabled:bg-green-300 disabled:border-green-300 disabled:text-green-100 disabled:cursor-not-allowed",
                              "default": "border border-gray-300 bg-gray-50 text-black hover:text-gray-600 disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-100 disabled:cursor-not-allowed"
                            })
                          ) }
                        >
                          { name }
                        </Button>);
                    }) }
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};


const Loady = ({ show, children, color, size, className, label }) => {
  return show ? (
    <div className={ "flex flex-col justify-center items-center " + className }>
      { label && <div className="text-2xl font-bold my-4" style={ { color } }>{ label }</div> }
      <img src={ LoadingAnimation } alt="Loading..." style={ { width: size, height: size, color } } />
    </div>
  ) : (
    children
  );
};


export {
  Contexture,
  ContextureSwitch,
  ContexturePanel,
  ContextureCategory,
  ContextureButton,
  ContextureChild,
  ContextureRow,
  ContextureCombobox,
  Modality,
  FormifyInput,
  FormifySelect,
  FormifyPalette,
  FormifyTextarea,
  FormifyCombobox,
  FormifyLevel,
  FormifyMultiSelect,
  FormifyMultiOption,
  FormifyMultiAll,
  Loady,
  Flaticon
};