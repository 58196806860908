import { Radio, RadioGroup } from "@headlessui/react";
import { ContextureButton, ContextureCategory, ContextureChild, ContexturePanel, Flaticon, FormifyInput } from "../../../../components/WePack";
import { clsx } from "../../../../modules/Utils";

const POS_X = 245;
const POS_Y = 34;

const MILTIPLER_OPTIONS = [
  { value: 0.7, label: 'Molto Piccolo' },
  { value: 0.8, label: 'Piccolo' },
  { value: 0.9, label: 'Medio' },
  { value: 1, label: 'Normale' }
];

const SettingsPanel = ({ show, onClose, fontSize, cellHeight, cellWidth, setParameter, parameters }) => {
  return (
    <ContexturePanel simple
      onClose={ onClose }
      show={ show }
      position={ { x: POS_X, y: POS_Y } }
    >
      <ContextureCategory label="Dimensioni">
        {/* Moltiplicatore */ }
        <ContextureChild>
          <ContextureButton>
            <Flaticon className="w-4 h-4" type="rr" name="text-size" />

            <span className="text-gray-800 text-start flex-1">
              Moltiplicatore
            </span>

            <Flaticon name="angle-right" type="rr" size={ 11 } />
          </ContextureButton>

          <ContexturePanel position={ { x: POS_X + 215, y: POS_Y + 25 } }>
            <RadioGroup
              value={ +parameters?.sizeMultipler || 0.9 }
              onChange={ (val) => setParameter('sizeMultipler', val) }
            >
              { MILTIPLER_OPTIONS.map((opt) => (
                <Radio key={ opt.value } value={ opt.value }>
                  { ({ checked }) => (
                    <ContextureButton addClasses={ checked && "bg-green-200 hover:bg-green-200" }>
                      <Flaticon name="check" type="rr" className={ clsx("h-4 w-4", !checked && "invisible") } />

                      <div className="flex space-x-2">
                        <h1 className="w-24 text-start">
                          { opt.label }
                        </h1>

                        <div className="w-5">
                          <div
                            className="bg-white/60 border border-gray-300"
                            style={ { width: cellWidth * opt.value, height: cellHeight * opt.value } }
                          />
                        </div>

                        <div style={ { fontSize: fontSize * opt.value } }>
                          { new Date().toISOString().split('T')[ 0 ].split('-').reverse().join('/') }
                        </div>
                      </div>
                    </ContextureButton>
                  ) }
                </Radio>
              )) }
            </RadioGroup>
          </ContexturePanel>
        </ContextureChild>

        {/* Altezza righe */ }
        <div className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap">
          <Flaticon name="text-height" className="w-4 h-4" type="rr" />

          <span className="text-gray-800 text-start flex-1 select-none">
            L'altezza righe
          </span>

          <div>
            <FormifyInput advanced
              className="pr-0 pl-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b w-16"
              type="number"
              timeout={ 300 }
              defaultValue={ parameters?.cellHeight || cellHeight }
              onChange={ (val) => {
                setParameter('cellHeight', val);
              } }
            />
          </div>
        </div>
      </ContextureCategory>

      <ContextureCategory label="Colori">
        <div className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap">
          <span className="text-gray-800 text-start flex-1 select-none">
            Oggi
          </span>

          <FormifyInput advanced
            timeout={ 300 }
            className="p-0 m-0 w-12 h-6"
            type="color"
            defaultValue={ parameters?.todayColor || "#ff0000" }
            onChange={ (val) => setParameter('todayColor', val) }
          />
        </div>

        <div className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap">
          <span className="text-gray-800 text-start flex-1 select-none">
            Giorno festivo
          </span>

          <FormifyInput advanced
            timeout={ 300 }
            className="p-0 m-0 w-12 h-6"
            type="color"
            defaultValue={ parameters?.holidayColor || '#f0f0f0' }
            onChange={ (val) => setParameter('holidayColor', val) }
          />
        </div>

        <div className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap">
          <span className="text-gray-800 text-start flex-1 select-none">
            Commessa
          </span>

          <FormifyInput advanced
            timeout={ 300 }
            className="p-0 m-0 w-12 h-6"
            type="color"
            defaultValue={ parameters?.commessaTaskColor || '#3b82f6' }
            onChange={ (val) => setParameter('commessaTaskColor', val) }
          />
        </div>

        <div className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap">
          <span className="text-gray-800 text-start flex-1 select-none">
            Fase
          </span>

          <FormifyInput advanced
            timeout={ 300 }
            className="p-0 m-0 w-12 h-6"
            type="color"
            defaultValue={ parameters?.faseTaskColor || '#22c55e' }
            onChange={ (val) => setParameter('faseTaskColor', val) }
          />
        </div>

        <div className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap">
          <span className="text-gray-800 text-start flex-1 select-none">
            Sotto-Fase
          </span>

          <FormifyInput advanced
            timeout={ 300 }
            className="p-0 m-0 w-12 h-6"
            type="color"
            defaultValue={ parameters?.grpModificabileTaskColor || '#22c55e' }
            onChange={ (val) => setParameter('grpModificabileTaskColor', val) }
          />
        </div>

        <div className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap">
          <span className="text-gray-800 text-start flex-1 select-none">
            Attività [=1]
          </span>

          <FormifyInput advanced
            timeout={ 300 }
            className="p-0 m-0 w-12 h-6"
            type="color"
            defaultValue={ parameters?.timesheetLowColor || '#d1d5db' }
            onChange={ (val) => setParameter('timesheetLowColor', val) }
          />
        </div>

        <div className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap">
          <span className="text-gray-800 text-start flex-1 select-none">
            Attività [=2]
          </span>

          <FormifyInput advanced
            timeout={ 300 }
            className="p-0 m-0 w-12 h-6"
            type="color"
            defaultValue={ parameters?.timesheetMediumColor || '#9ca3af' }
            onChange={ (val) => setParameter('timesheetMediumColor', val) }
          />
        </div>

        <div className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap">
          <span className="text-gray-800 text-start flex-1 select-none">
            Attività [≥3]
          </span>

          <FormifyInput advanced
            timeout={ 300 }
            className="p-0 m-0 w-12 h-6"
            type="color"
            defaultValue={ parameters?.timesheetHighColor || '#6b7280' }
            onChange={ (val) => setParameter('timesheetHighColor', val) }
          />
        </div>
      </ContextureCategory>
    </ContexturePanel>
  );
};

export default SettingsPanel;