import { useEffect, useState } from "react";
import { useNotify } from "../../../components/Notify";
import Moment from "../../../../components/Moment";
import req from "../../../../modules/Request";
import { FormifyInput } from "../../../../components/WePack";
import { clsx } from "../../../../modules/Utils";


const CutForm = ({ task, setTasks, onClose, holidays }) => {
  const { notify } = useNotify();

  const min = task.split?.start ?? task.start;
  const max = task.split?.end ?? task.end;

  const [ from, setFrom ] = useState(task.selectedDate);
  const [ to, setTo ] = useState(task.selectedDate);

  useEffect(() => {
    let availableFrom = Moment(task.selectedDate).add(-1).value();
    let availableTo = Moment(task.selectedDate).add(1).value();

    while (holidays.includes(availableFrom)) {
      availableFrom = Moment(availableFrom).add(-1).value();
    }

    while (holidays.includes(availableTo)) {
      availableTo = Moment(availableTo).add(1).value();
    }

    setFrom(availableFrom);
    setTo(availableTo);
  }, [ task ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    req("new-split", { id: task.split?.id ?? task.id, father: task.id, from, to })
      .then((res) => {
        if (res.success) {
          setTasks((tasks) => tasks.map(t => {
            if (t.id === task.id) {
              return {
                ...t,
                dates: res.data.map(st => {
                  return {
                    ...st,
                    isDraggable: true,
                    isResizable: true
                  };
                })
              };
            } else {
              return t;
            }
          }));
        } else {
          notify(res.error, "Errore durante la divisione del task.", 'error');
        }
      });
    onClose();
  };

  return (
    <form className="min-w-[220px] flex flex-col items-center text-center" onSubmit={ handleSubmit }>
      <div className="w-full font-semibold border-b border-gray-400">
        Modifica Task
      </div>

      <div className="flex flex-col justify-center items-center space-y-1 pb-2">
        <div className="flex flex-col w-full">
          <label>Taglio Dal</label>

          <FormifyInput
            type="date"
            value={ from }
            onInput={ (val) => setFrom(val) }
            onInvalid={ (e) => {
              e.preventDefault();
              notify('Taglio Dal', e.target.validationMessage, 'error');
            } }
            max={ Moment(to).add(-1).value() }
            min={ Moment(min).value() }
          />
        </div>

        <div className="flex flex-col w-full">
          <label>Taglio Al</label>

          <FormifyInput
            type="date"
            value={ to }
            onInput={ (val) => setTo(val) }
            max={ Moment(max).value() }
            min={ Moment(from).add(1).value() }
          />
        </div>

        <button
          className={ clsx(
            "border disabled:bg-green-300 disabled:border-green-200 rounded-sm",
            "px-4 py-0.5 duration-75 bg-green-500 hover:bg-green-400 border-green-400",
            "hover:border-green-300 text-white"
          ) }
        >
          Taglia
        </button>
      </div>
    </form>
  );
};

export default CutForm;