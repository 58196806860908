import { useEffect, useMemo, useState } from "react";
import useDevices from "./hooks/useDevices";
import req from "../../../modules/Request";
import { useNavigator } from "../../components/Navigator";
import { useNotify } from "../../components/Notify";
import { Flaticon } from "../../../components/WePack";


const Devices = () => {
  const { notify } = useNotify();
  const { setQuickAccessPanel } = useNavigator();

  const [ loading, setLoading ] = useState(false);
  const { devices, setDevices, fetchDevices, loadingDevices } = useDevices();

  const lastUpdate = useMemo(() => {
    return new Date().toISOString().split("T")[0].split("-").reverse().join("/") + " " + new Date().toLocaleTimeString().split(":").slice(0, 2).join(":");
  }, [ loadingDevices ]);

  useEffect(() => {
    setQuickAccessPanel([]);
  }, []);

  const handleDelete = async (id) => {
    setLoading(true);
    
    const res = await req('delete-device', { id });
    if (res.success) {
      setDevices(devices.filter(device => device.id !== id));
    } else {
      notify("Error", "Please contact the administrator", 'error');
    }

    setLoading(false);
  };

  return (
    <div className="flex justify-center p-2">
      <div className="max-w-[800px] w-full border rounded-lg px-4 py-2 flex flex-col">
        <div className="w-full flex justify-between">
          <h1 className="text-2xl">Dispositivi</h1>
          <div className="text-nowrap flex items-center space-x-2">
            <h3 className="text-gray-400">{ lastUpdate }</h3>
            <button onClick={ () => fetchDevices() }>
              <Flaticon name="rotate-right" type="rr" size={ 16 } />
            </button>
          </div>
        </div>

        <div className="flex flex-col divide-y">
          { !loadingDevices ? (
            devices?.length > 0 ? (
              devices.map(({ id, name, dateAdded, lastSession }, index) => (
                <div key={ index } className="flex items-center py-2">
                  <div className="pr-4">
                    { name?.toLowerCase().includes("windows") ? (
                      <Flaticon name="brands-windows" size={ 32 } />
                    ) : name?.toLowerCase().includes("android") ? (
                      <Flaticon name="brands-android" size={ 32 } />
                    ) : (
                      <Flaticon name="devices" type="rr" size={ 32 } />
                    ) }
                  </div>
                  <div className="flex flex-col flex-1">
                    <span className="font-medium">{ name ?? "Unknown" }</span>
                    <div className="flex space-x-4">
                      <span>Data di accesso: <span className="font-medium">{ dateAdded > 2 ? (
                        dateAdded + " giorn fa"
                      ) : dateAdded > 1 ? (
                        "Ieri"
                      ) : (
                        "Oggi"
                      ) }</span></span>
                      <span>L'ultima sessione: <span className="font-medium">{ lastSession > 2 ? (
                        lastSession + " giorni fa"
                      ) : lastSession > 1 ? (
                        "Ieri"
                      ) : (
                        "Oggi"
                      ) }</span></span>
                    </div>
                  </div>
                  <div className="h-full flex items-end">
                    <button
                      className="text-red-600 hover:text-red-400 disabled:text-red-200"
                      onClick={ () => handleDelete(id) }
                      disabled={ loading }
                    >
                      Disconnetti
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex justify-center items-center py-4">
                <span className="text-gray-400 text-lg">
                  Nessun dispositivo trovato
                </span>
              </div>
            )
          ) : (
            <div className="flex justify-center items-center py-4">
              <span className="text-gray-400 text-lg">
                Caricamento...
              </span>
            </div>
          ) }
        </div>
      </div>
    </div>
  );
};


export default Devices;