import { useEffect, useMemo, useState } from "react";
import { useNotify } from "../../../components/Notify";
import req from "../../../../modules/Request";
import { FormifyInput } from "../../../../components/WePack";
import { clsx } from "../../../../modules/Utils";


const MilestoneFormEdit = ({ task, setTasks, holidays, onClose, workingHours }) => {
  const { notify } = useNotify();

  const [ start, setStart ] = useState(task.start);
  const [ label, setLabel ] = useState(task.name == task.origin ? '' : task.name);

  useEffect(() => {
    setStart(task.start);
    setLabel(task.name == task.origin ? '' : task.name);
  }, [ task ]);

  const reqData = useMemo(() => {
    const data = { ...task };
    data.label = label;
    data.start = start;
    data.date = start;

    return data;
  }, [ start, label ]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (holidays.includes(start)) {
      notify('Data Inizio', "La data non può essere un giorno festivo.", 'error');
      return;
    }

    req('sync-task', { task: reqData }).then(async (res) => {
      if (res.success) {
        setTasks(tasks => tasks.map(t => t.id == task.id ? { ...reqData, moved: 1, name: reqData.label || task.origin } : t));
      } else {
        notify(res.error, "Errore durante l'aggiornamento del task.", 'error');
      }
    });

    onClose();
  };

  return (
    <form className="min-w-[220px] flex flex-col items-center text-center" onSubmit={ handleSubmit }>
      <div className="w-full font-semibold border-b border-gray-400">Modifica Task</div>

      <div className="flex flex-col justify-center items-center space-y-1 pb-2">
        <div className="flex flex-col w-full">
          <label>Titolo</label>

          <FormifyInput
            value={ label }
            maxLength={ 50 }
            onInput={ (val) => task.record == 'M' && setLabel(val) }
            placeholder={ task.origin }
            disabled={ task.record != "M" }
          />
        </div>

        <div className="flex flex-col w-full">
          <label>Data</label>

          <FormifyInput required
            type="date"
            value={ start }
            onInput={ (val) => {
              if (!holidays.includes(val)) {
                setStart(val);
              } else {
                notify('Data Inizio', "La data non può essere un giorno festivo.", 'error');
              }
            } }
          />
        </div>

        <button
          className={ clsx(
            "border disabled:bg-green-300 disabled:border-green-200 rounded-sm",
            "px-4 py-0.5 duration-75 bg-green-500 hover:bg-green-400 border-green-400",
            "hover:border-green-300 text-white"
          ) }
        >
          Salva
        </button>
      </div>
    </form>
  );
};

export default MilestoneFormEdit;