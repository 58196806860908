import { useEffect, useMemo, useRef, useState } from "react";
import WeGantt, { transformParentToWBS, transformWBSToParent } from "../../../components/WeGantt";
import req from "../../../modules/Request";
import { ContexturePanel, ContextureRow, Loady, Modality } from "../../../components/WePack";
import { clsx, num } from "../../../modules/Utils";
import TimesheetPopup from "./components/TimesheetPopup";
import MilestonePopup from "./components/MilestonePopup";
import SettingsPanel from "./components/SettingsPanel";
import FilterPanel from "./components/FilterPanel";
import { useNotify } from "../../components/Notify";
import MilestoneCell from "./components/MilestoneCell";
import TaskCell from "./components/TaskCell";
import TimesheetCell from "./components/TimesheetCell";
import TableRow from "./components/TableRow";
import TaskContextMenu from "./components/TaskContextMenu";
import CutForm from "./components/CutForm";
import SplitEditForm from "./components/SplitEditForm";
import TaskFormEdit from "./components/TaskFormEdit";
import MilestoneFormEdit from "./components/MilestoneFormEdit";
import useTasks from "./hooks/useTasks";
import useHolidays from "./hooks/useHolidays";
import useParameters from "./hooks/useParameters";
import { useNavigator } from "../../components/Navigator";
import { useLoadingContext } from "../../components/LoadingScreen";
import { useAuth } from "../../components/Auth";
import { useContextMenu } from "../../components/ContextMenu";
import { useFloatPanel } from "../../components/FloatPanel";
import Moment from "../../../components/Moment";

const CELL_HEIGHT = 25;
const CELL_WIDTH = 20;
const FONT_SIZE = 13;
const WORKING_HOURS = 8;

export default function TacticalPlanning() {
  const { notify } = useNotify();
  const { setQuickAccessPanel } = useNavigator();
  const { showLoadingScreen, setShowLoadingScreen } = useLoadingContext();
  const { setContextMenu } = useContextMenu();
  const { setFloatPanel } = useFloatPanel();

  const parameters = useParameters();
  const tasks = useTasks({ parameters: parameters.data });
  const holidays = useHolidays();
  const [ viewType, setViewType ] = useState('days');
  const finedTasks = useMemo(() => {
    return tasks.data?.map((task) => ({
      ...task,
      color: task.record == 'COM' ? (
        parameters.data?.commessaTaskColor || '#3b82f6'
      ) : [ 'ST', 'T' ].includes(task.record) ? (
        parameters.data?.faseTaskColor || '#22c55e'
      ) : [ 'ODP' ].includes(task.record) && (
        parameters.data?.grpModificabileTaskColor || '#f59e0b'
      ),
      dates: task.dates && task.dates.length > 0 && task.dates.map((date) => ({
        ...date,
        isDraggable: true,
        isResizable: true
      })),
      timesheets: task.timesheets && task.timesheets.length > 0 && task.timesheets.map((tm) => {
        return {
          ...tm,
          color: tm.amountOfResources > 2 ? (
            parameters.data?.timesheetHighColor || '#999999'
          ) : tm.amountOfResources > 1 ? (
            parameters.data?.timesheetMediumColor || '#c4c4c4'
          ) : (
            parameters.data?.timesheetLowColor || '#e3e3e3'
          )
        };
      }),
      className: clsx(
        "border border-gray-100 scale-75 w-3 h-3",
        task.orderType == 'RD' && "rounded-full",
        task.orderType == 'CH' && "rotate-45",
        task.orderType == 'CL' && "rotate-0",
        task.confirmedDeliveryDate != undefined ? "bg-green-400" : "bg-red-600"
      )
    }));
  }, [ tasks.data, parameters.data ]);

  

  const [ showFilterPanel, setShowFilterPanel ] = useState(false);
  const [ showSettingsPanel, setShowSettingsPanel ] = useState(false);
  const [ deleteTask, setDeleteTask ] = useState(false);


  /*
  const { userData } = useAuth();
  const WB_CONNECTION = useRef(null);
  const [ peopleList, setPeopleList ] = useState([])
  const [ showPeople, setShowPeople ] = useState(false);;

  useEffect(() => {
    WB_CONNECTION.current = new WebSocket(config.websocket);

    WB_CONNECTION.current.addEventListener("open", () => {
      WB_CONNECTION.current.send(JSON.stringify({ type: "open", username: userData.fullname }));
    });

    WB_CONNECTION.current.addEventListener("close", () => {
      WB_CONNECTION.current.send(JSON.stringify({ type: "close", username: userData.fullname }));
    });
    return () => WB_CONNECTION.current && WB_CONNECTION.current.close();
  }, []);

  useEffect(() => {
    WB_CONNECTION.current.addEventListener("message", (event) => {
      const data = JSON.parse(event.data);

      if (data.type === 'task-edit') {
        notify("Attenzione", "Utente " + data.username + " ha modificato il task " + data.task.name + " della commessa " + data.projectNumber, "info");

        if (data.projectNumber === tasks.project) {
          tasks.setData(tasks => tasks.map((t) => t.id == data.task.id ? data.task : t));
        }
      } else if (data.type === 'open') {
        
      } else if(data.type === 'close') {

      } else if(data.type === 'people') {
        setPeopleList(data.people);
      }
    });
  }, [ tasks.project, tasks.data ]);
  */

  // ON TASK MOVE HANDLE
  const handleMove = async (task) => {
    req("sync-task", { task }).then((res) => {
      if (res.success) {
        // WB_CONNECTION.current.send(JSON.stringify({ type: "task-edit", task, projectNumber: tasks.project, username: userData.fullname }));
      } else {
        notify(res.error, "Errore sincronizzazione task", 'error');
      }
    });
    tasks.setData(tasks => tasks.map((t) => t.id == task.id ? { ...task, moved: 1 } : t));
  };

  // ON TASK RESIZE HANDLE
  const handleResize = async (task) => {
    req("sync-task", { task })
      .then((res) => {

        if (res.success) {
          // WB_CONNECTION.current.send(JSON.stringify({ type: "task-edit", task, projectNumber: tasks.project, username: userData.fullname }));
        } else {
          notify(res.error, "Errore sincronizzazione task", 'error');
        }
      });
    tasks.setData(tasks => tasks.map((t) => t.id == task.id ? { ...task, moved: 1 } : t));
  };

  const handleOpenEditForm = ({ task, event }) => {
    if (task.type != 'project') {
      if (task.split) {
        setFloatPanel({
          Component: ({ onClose }) => (
            <SplitEditForm
              task={ task }
              split={ task.split }
              setTasks={ tasks.setData }
              holidays={ holidays }
              workingHours={ WORKING_HOURS }
              onClose={ onClose }
            />
          ),
          position: { x: event.clientX, y: event.clientY }
        });
      } else if (task.record === 'M' && task.type === 'milestone') {
        setFloatPanel({
          Component: ({ onClose }) => (
            <MilestoneFormEdit
              task={ task }
              setTasks={ tasks.setData }
              holidays={ holidays }
              workingHours={ WORKING_HOURS }
              onClose={ onClose }
            />
          ),
          position: { x: event.clientX, y: event.clientY }
        });
      } else {
        setFloatPanel({
          Component: ({ onClose }) => (
            <TaskFormEdit
              holidays={ holidays }
              task={ task }
              workingHours={ WORKING_HOURS }
              setTasks={ tasks.setData }
              onClose={ onClose }
            />
          ),
          position: { x: event.clientX, y: event.clientY }
        });
      }
    }
  };

  const handleOpenCutForm = ({ task, event }) => {
    setFloatPanel({
      Component: ({ onClose }) => (
        <CutForm
          holidays={ holidays }
          task={ task }
          workingHours={ WORKING_HOURS }
          setTasks={ tasks.setData }
          onClose={ onClose }
        />
      ),
      position: { x: event.clientX, y: event.clientY }
    });
  };

  const handleContextMenu = ({ task, event }) => {
    if (task.type === 'milestone' && task.record !== 'M') return;

    let buttonsGroup = [];

    const editButton = {
      id: 'task-edit', name: "Modifica", icon: { type: 'rr', name: 'edit' }, onClick: () => {
        handleOpenEditForm({ task, event });
      }
    };

    const cutButton = {
      id: 'task-cut', name: "Taglia", icon: { type: 'rr', name: 'scissors' }, onClick: () => {
        handleOpenCutForm({ task, event });
      }
    };

    const addSubTaskButton = {
      id: 'add-new-subtask', name: "Aggiungi attività", icon: { type: 'rr', name: 'square-plus' }, onClick: () => {
        req('new-subtask', { from: task.id }).then(async (res) => {
          if (res.success) {
            tasks.resync();
          }
        });
      }
    };

    const expandButton = {
      id: 'expand', name: "Espandi", icon: { type: 'rr', name: 'caret-square-down' }, onClick: () => {
        tasks.setData((tasks) => tasks.map(t => {
          if (t.id === task.id) {
            return { ...t, showChilds: true };
          } else if (t.position.startsWith(task.position + '.')) {
            return { ...t, showChilds: true };
          } else {
            return t;
          }
        }));
      }
    };

    const folderButton = {
      id: 'folder', name: "Riduci", icon: { type: 'rr', name: 'caret-quare-up' }, onClick: () => {
        tasks.setData((tasks) => tasks.map(t => {
          if (t.id === task.id) {
            return { ...t, showChilds: false };
          } else if (t.position.startsWith(task.position + '.')) {
            return { ...t, showChilds: false };
          } else {
            return t;
          }
        }));
      }
    };

    const convertToMilestoneButton = {
      id: 'convert-to-milestone', name: "Converti in Milestone", icon: { type: 'rr', name: 'convert-shapes' }, onClick: () => {
        req('sync-task', { task: { ...task, record: 'M' } }).then(async (res) => {
          if (res.success) {

          } else {
            notify('Errore', "Errore durante la conversione in Milestone.", 'error');
          }
        });
        tasks.setData(tasks => tasks.map(t => t.id === task.id ? { ...task, date: task.start, type: 'milestone', record: 'M' } : t));
      }
    };

    const convertToTaskButton = {
      id: 'convert-to-task', name: "Converti in Task", icon: { type: 'rr', name: 'convert-shapes' }, onClick: () => {
        req('sync-task', { task: { ...task, record: 'ST' } }).then(async (res) => {
          if (res.success) {

          } else {
            notify('Errore', "Errore durante la conversione in Milestone.", 'error');
          }
        });
        tasks.setData(tasks => tasks.map(t => t.id === task.id ? { ...task, date: task.start, type: 'task', record: 'ST' } : t));
      }
    };

    const deleteButton = {
      id: 'delete-task', name: "Elimina", icon: { type: 'rr', name: 'trash' }, onClick: () => {
        setDeleteTask(task.id);
      }
    };

    if (task.showChilds !== undefined) {
      buttonsGroup.push(folderButton, expandButton);
    }

    if (task.record === 'M' && task.type === 'milestone') {
      buttonsGroup.push(editButton, convertToTaskButton, deleteButton);
    }


    if (task.type === 'task') {
      buttonsGroup.push(editButton);
    }

    if (task.type === 'task' && task.selectedDate) {
      buttonsGroup.push(cutButton);
    }

    if (task.record === 'ST') {
      buttonsGroup.push(deleteButton);

      if (!task.dates || task.dates?.length === 0) {
        buttonsGroup.push(convertToMilestoneButton);
      }
    }

    if (task.record === 'T') {
      buttonsGroup.push(addSubTaskButton);
    }

    setContextMenu({ position: { x: event.clientX, y: event.clientY }, data: task, context: [ { id: task.id, name: task.name, items: buttonsGroup } ] });
  };

  // ADD FLOAT PANEL ACTIONS
  useEffect(() => {
    setQuickAccessPanel([
      { name: "Aggiorna", icon: { type: 'rr', name: 'refresh' }, onClick: () => tasks.resync(), type: "button" },
      { name: "Filtri", icon: { type: 'rr', name: 'filter' }, onClick: () => setShowFilterPanel(true), type: "button" },
      { name: "Impostazioni", icon: { type: 'rr', name: 'settings' }, onClick: () => setShowSettingsPanel(true), type: "button" },
      /* { name: "Persone", icon: { type: 'rr', name: 'people' }, onClick: () => setShowPeople(true), type: "button" } */
    ]);
  }, []);

  return (
    <div className="w-full h-full">

      {/*
        <ContexturePanel
          show={ showPeople }
          onClose={ () => setShowPeople(false) }
          position={ { x: 250, y: 34 } }
        >
          { peopleList.length > 0 ? (
            peopleList.map((person) => {
              return (
                <ContextureRow>
                  { person }
                </ContextureRow>
              );
            })
          ) : (
            <ContextureRow>
              Nessuna persona presente
            </ContextureRow>
          ) }
        </ContexturePanel>
      */}

      { /* ELIMINAZIONE DEL TASK */ }
      <Modality
        label="Attenzione"
        show={ deleteTask }
        icon={ { name: "cross-circle", type: "sr", className: "text-red-500" } }
        onClose={ () => setDeleteTask(false) }
        buttons={ [
          { name: "Chiudi", onClick: ({ onClose }) => { onClose(); } },
          {
            name: "Elimina", styleSet: "error", onClick: ({ onClose }) => {
              req('del-subtask', { id: deleteTask });
              tasks.setData(tasks => tasks.filter(t => t.id !== deleteTask));
              onClose();
            }
          }
        ] }
      >
        Sei sicuro di voler eliminare questo task?
      </Modality>

      <FilterPanel
        show={ showFilterPanel }
        onClose={ () => setShowFilterPanel(false) }
        setViewType={ setViewType }
        viewType={ viewType }
        selectedCommessa={ tasks.project }
        onSubmit={ (val) => tasks.setProject(val) }
      />

      <SettingsPanel
        cellHeight={ parameters.data?.cellHeight || CELL_HEIGHT }
        cellWidth={ CELL_WIDTH }
        fontSize={ FONT_SIZE }
        show={ showSettingsPanel }
        onClose={ () => setShowSettingsPanel(false) }
        parameters={ parameters.data }
        setParameter={ (parameter, value) => {
          if (!(parameters.data?.[ parameter ] === value)) {
            parameters.setData(parameter, value);
          }
        } }
      />

      { showLoadingScreen && <div className="w-full h-full absolute bg-transparent z-[999]" /> }

      <Loady
        className="w-full h-full flex justify-center items-center"
        color="#55b6c9"
        show={ tasks.isLoading || parameters.isLoading }
        label="Caricamento Gantt..."
        size="40px"
      >
        { !tasks.project || !finedTasks ? (
          <div className="w-full h-36 flex justify-center items-center text-xl">
            Cerca una commessa valida per visualizzare il Gantt...
          </div>
        ) : (<>
          <WeGantt
            cellHeight={ (parameters.data?.cellHeight || CELL_HEIGHT) * (parameters.data?.sizeMultipler || 0.9) }
            cellWidth={ CELL_WIDTH * (parameters.data?.sizeMultipler || 0.9) }
            fontSize={ FONT_SIZE * (parameters.data?.sizeMultipler || 0.9) }
            tasks={ finedTasks }
            holidays={ holidays ?? [] }
            displayHolidays={ holidays ?? [] }
            styles={ {
              holidayColor: parameters.data?.holidayColor || '#f0f0f0',
              todayColor: parameters.data?.todayColor || '#ff0000'
            } }
            workingHours={ WORKING_HOURS }
            viewMode={ viewType }

            onMove={ handleMove }
            onResize={ handleResize }

            onTaskDoubleClick={ handleOpenEditForm }

            onTaskContextMenu={ handleContextMenu }

            onMilestoneHover={ ({ task }) => (
              <MilestonePopup task={ task } />
            ) }

            onTimesheetHover={ ({ timesheet }) => {
              return (
                <TimesheetPopup
                  amountOfResources={ timesheet.amountOfResources }
                  amountOfHours={ timesheet.hours }
                  date={ timesheet.date.split('-').reverse().join('/') }
                  resources={ timesheet.details }
                />
              );
            } }

            TimesheetCell={ (timesheet) => (
              <TimesheetCell
                sizeMultipler={ parameters.data?.sizeMultipler || 0.9 }
                fontSize={ FONT_SIZE }
                { ...timesheet }
              />
            ) }

            TableColumns={ () => {
              return [
                <div className="w-full flex px-2">Nome</div>,
                <>Ore Budg.</>,
                <>Ore Cons.</>,
                <>Budg. Res.</>,
                <>Ore a finire</>
              ];
            } }

            TableRow={ ({ task }) => {
              return (
                <TableRow
                  fontSize={ FONT_SIZE * (parameters.data?.sizeMultipler || 0.9) }
                  cellHeight={ (parameters.data?.cellHeight || CELL_HEIGHT) * (parameters.data?.sizeMultipler || 0.9) }
                  sizeMultipler={ parameters.data?.sizeMultipler || 0.9 }
                  task={ task }
                  setTasks={ tasks.setData }
                  holidays={ holidays }
                  onContextMenu={ ({ event }) => {
                    handleContextMenu({ task, event });
                    // setShowTaskContextMenu({ show: true, posX: event.clientX, posY: event.clientY, task, reqFrom: 'table' });
                  } }
                />
              );
            } }

            MilestoneCell={ ({ task, from }) => {
              return (
                <MilestoneCell
                  from={ from }
                  fontSize={ FONT_SIZE * (parameters.data?.sizeMultipler || 0.9) }
                  cellWidth={ CELL_WIDTH * (parameters.data?.sizeMultipler || 0.9) }
                  { ...task }
                />
              );
            } }

            TaskCell={ ({ task, splitTask }) => {
              return (
                <TaskCell
                  task={ task }
                  splitTask={ splitTask }
                />
              );
            } }
          />
        </>) }
      </Loady>
    </div>
  );
}