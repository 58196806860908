import { useEffect, useRef, useState } from "react";
import { Flaticon, FormifyInput } from "../../../components/WePack";
import { useContextMenu } from "../../components/ContextMenu";
import { useFloatPanel } from "../../components/FloatPanel";
import useCalendar from "./hooks/useCalendar";



const CalendarManagement = () => {
  const { calendar, insertDate, deleteDate } = useCalendar();
  const { setContextMenu } = useContextMenu();
  const { setFloatPanel } = useFloatPanel();

  const handleShowAddNewDatePanel = (e) => {
    setFloatPanel({
      Component: ({ handleClose }) => <AddNewDatePanel onClose={ handleClose } insertDate={ insertDate } />,
      position: { x: e.clientX, y: e.clientY }
    });
  };


  const handleContextMenu = (e, date) => {
    e.preventDefault();

    setContextMenu({
      context: [
        {
          id: 'general', name: 'general', items: [
            { id: 'add', name: 'Aggiungi', icon: { name: 'plus', type: 'br' }, onClick: () => handleShowAddNewDatePanel(e) },
            { id: 'delete', name: 'Elimina', icon: { name: 'trash', type: 'rr' }, onClick: () => deleteDate(date) }
          ]
        }
      ],
      position: { x: e.clientX, y: e.clientY }
    });
  };

  return (
    <div className="flex flex-col justify-center">
      <div className="p-4 w-full flex flex-col justify-center">
        <div className="flex flex-col w-full">
          <div className="text-2xl text-black-800 py-2 px-3 border-b border-gray-300 w-full flex justify-between text-start">
            <span className="font-semibold">Gestione Calendario</span>

            <button
              onClick={ handleShowAddNewDatePanel }
              className="flex items-center justify-center px-1.5 space-x-1 hover:bg-gray-200 rounded-md text-gray-800 text-nowrap text-sm"
            >
              <span>Aggiungi</span>
              <Flaticon name="plus" type="br" size={ 11 } />
            </button>
          </div>

          <table className="*:*:*:px-2 w-full text-sm">
            <thead>
              <tr className="*:text-start *:py-2 *:bg-white z-10 shadow-md sticky top-0">
                <td className="font-bold">Data</td>
                <td className="font-bold">Giorno</td>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              { calendar.map((date) => (
                <tr key={ date } onContextMenu={ (e) => handleContextMenu(e, date) }>
                  <td>{ date }</td>
                  <td>{ new Date(date.split('/').reverse().join('-')).toLocaleDateString('it-IT', { weekday: 'long' }) }</td>
                </tr>
              )) }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const AddNewDatePanel = ({ insertDate, onClose }) => {
  const inputDateRef = useRef(null);
  const [ date, setDate ] = useState(new Date().toISOString().split('T')[ 0 ]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if ([ 6, 0 ].includes(new Date(date).getDay())) {
      return;
    }

    insertDate(date);
    onClose();
  };

  useEffect(() => {
    if (!inputDateRef.current) return;
    
    if ([ 6, 0 ].includes(new Date(date).getDay())) {
      inputDateRef.current.setCustomValidity('La data non può essere un sabato o una domenica');
      inputDateRef.current.reportValidity();
    } else {
      inputDateRef.current.setCustomValidity('');
    }
  }, [ date ]);

  return (
    <form className="flex flex-col space-y-2" onSubmit={ handleSubmit }>
      <FormifyInput
        refe={ inputDateRef }
        type="date"
        label="Data"
        value={ date }
        onInput={ (val) => setDate(val) }
      />
      <button>Salva</button>
    </form>
  );
};

export default CalendarManagement;