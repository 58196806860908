import { useEffect, useState } from "react";
import { ContextureButton, ContextureCategory, ContextureChild, ContextureCombobox, ContexturePanel, Flaticon } from "../../../../components/WePack";
import sls from "react-secure-storage";
import { clsx } from "../../../../modules/Utils";
import req from "../../../../modules/Request";
import { Radio, RadioGroup } from "@headlessui/react";
import * as session from "../../../../modules/Session";

const POS_X = 175;
const POS_Y = 34;

const VIEWMODE_OPTIONS = [
  { label: 'Settimana', value: 'weeks' },
  { label: 'Giorno', value: 'days' }
];

const FilterPanel = ({ show, onClose, selectedCommessa, onSubmit, setViewType, viewType }) => {
  const [ suggs, setSuggs ] = useState(session.getStore("commesse") || []);

  useEffect(() => {
    req('get-commesse').then((res) => {
      if (res.success) {
        const newSuggs = [
          { label: 'Tutto', value: 'Tutto' },
          ...res.data.commesse.map(commessa => ({ label: commessa, value: commessa }))
        ];
        session.addStore("commesse", newSuggs);
        setSuggs(newSuggs);
      }
    });
  }, []);

  return (
    <ContexturePanel simple
      onClose={ onClose }
      show={ show }
      position={ { x: POS_X, y: POS_Y } }
    >
      { /** Visualizza per: settimana, giorno */ }
      <ContextureCategory label="Generali">
        <div className="flex justify-start items-center px-1 py-0.5 w-full space-x-2 text-nowrap">
          <ContextureChild>
            <ContextureButton>
              <Flaticon name="calendar" type="rr" className="w-4 h-4" />

              <span className="text-gray-800 text-start flex-1">
                Visualizzazione
              </span>

              <Flaticon name="angle-right" type="rr" size={ 11 } />
            </ContextureButton>

            <ContexturePanel position={ { x: POS_X + 190, y: POS_Y + 27 } }>
              <RadioGroup value={ viewType } onChange={ setViewType }>
                { VIEWMODE_OPTIONS.map((option) => (
                  <Radio key={ option.value } value={ option.value }>
                    { ({ checked }) => (
                      <ContextureButton addClasses={ checked && "bg-green-200 hover:bg-green-200" }>
                        <Flaticon name="check" type="rr" className={ clsx("h-4 w-4", !checked && "invisible") } />

                        <span className="flex space-x-2">
                          { option.label }
                        </span>
                      </ContextureButton>
                    ) }
                  </Radio>
                )) }
              </RadioGroup>
            </ContexturePanel>
          </ContextureChild>
        </div>
      </ContextureCategory>

      { /** Numero Commessa */ }
      <ContextureCategory label="Commessa">
        <ContextureCombobox
          options={ suggs }
          defaultValue={ selectedCommessa }
          width={ 220 }
          onSubmit={ (val) => {
            onSubmit(val);
            sls.setItem("tactical-planning-selected-commessa", val);
            onClose();
          } }
        >
          <Flaticon
            name="angle-right"
            type="rr"
            className="w-4 h-4 text-white"
          />
        </ContextureCombobox>
      </ContextureCategory>
    </ContexturePanel>
  );
};

export default FilterPanel;